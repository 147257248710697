import { getModuleData } from '@/services/api/module.api';
import {
  createRecord,
  deleteRecord,
  updateRecord,
} from '@/services/api/record.api';
import { createRevision, getRevisions } from '@/services/api/revisions.api';
import moment from 'moment';

const state = {
  moduleData: undefined,
  moduleStatus: '',
  revisions: [],
};
const mutations = {
  module_wkb_risk_analysis_request(state) {
    state.moduleStatus = 'loading';
  },
  module_wkb_risk_analysis_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  module_wkb_risk_analysis_revisions_success(state, data) {
    state.revisions = data;
  },
  module_wkb_risk_analysis_revisions_create_success(state, data) {
    state.revisions.push(data);
  },
  module_wkb_risk_analysis_chapter_create_success(state, record) {
    state.moduleData.CFFA_DHME_WKB_CHAPTER_STATUSES.records.push(record);
  },
  module_wkb_risk_analysis_chapter_update_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_DHME_WKB_CHAPTER_STATUSES.records.find(
        (item) => item.id === record.id
      ),
      record
    );
  },
  module_wkb_risk_analysis_substantiation_create_success(state, record) {
    state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATIONS.records.push(record);
  },
  module_wkb_risk_analysis_substantiation_document_upload_success(
    state,
    record
  ) {
    state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS.records.push(
      record
    );
  },
  module_wkb_risk_analysis_substantiation_document_delete_success(
    state,
    recordId
  ) {
    state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS.records =
      state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS.records.filter(
        (r) => r.id !== recordId
      );
  },
};
const actions = {
  fetchWkbRiskAnalysisModuleData(
    { commit },
    { projectId, moduleId, sessionId }
  ) {
    commit('module_wkb_risk_analysis_request');
    getModuleData(projectId, moduleId, sessionId)
      .then(async (data) => {
        commit('module_wkb_risk_analysis_success', data);

        let revisions = await getRevisions(
          projectId,
          data.CFFA_DHME_WKB_CHAPTER_STATUSES.id
        );
        commit('module_wkb_risk_analysis_revisions_success', revisions);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  async createWkbRiskAnalysisRevision({ commit, state, getters }) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_DHME_WKB_CHAPTER_STATUSES.id,
      },
      reason: `${moment().format('YYYY-MM-DD HH:mm')}_${
        getters.authenticatedUser.lastname
      }`,
    };
    try {
      let revision = await createRevision(body);
      commit('module_wkb_risk_analysis_revisions_create_success', revision);
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
    }
  },
  async createChapterSubstantiation({ commit, getters, state }, data) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATIONS.id,
      },
      record: data,
    };
    try {
      let record = await createRecord(body);
      commit('module_wkb_risk_analysis_substantiation_create_success', record);
      return Promise.resolve();
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      return Promise.reject(error);
    }
  },
  async createChapterStatus({ commit, getters, state }, { data }) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_DHME_WKB_CHAPTER_STATUSES.id,
      },
      record: data,
    };
    try {
      let record = await createRecord(body);
      commit('module_wkb_risk_analysis_chapter_create_success', record);
      return Promise.resolve();
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      return Promise.reject(error);
    }
  },
  async updateChapterStatus({ commit, getters, state }, { id, data }) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_DHME_WKB_CHAPTER_STATUSES.id,
      },
      record: data,
    };
    try {
      let record = await updateRecord(id, body);
      commit('module_wkb_risk_analysis_chapter_update_success', record);
      return Promise.resolve();
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      return Promise.reject(error);
    }
  },
  async uploadSubstantiationDocument({ commit, getters, state }, data) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS.id,
      },
      record: data,
    };
    try {
      let record = await createRecord(body);
      commit(
        'module_wkb_risk_analysis_substantiation_document_upload_success',
        record
      );
      return Promise.resolve();
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      return Promise.reject(error);
    }
  },

  async removeSubstantiationDocument({ commit, getters, state }, data) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS.id,
      },
      record: data,
    };
    try {
      await deleteRecord(data.id, body);
      commit(
        'module_wkb_risk_analysis_substantiation_document_delete_success',
        data.id
      );
      return Promise.resolve();
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
      return Promise.reject(error);
    }
  },
};
const getters = {
  wkbRiskAnalysisData: (state) => state.moduleData,
  wkbRiskAnalysisDataStatus: (state) => state.moduleStatus,
  wkbRiskAnalysisRevisions: (state) =>
    state.revisions.sort(
      (a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix()
    ),
  wkbRiskAnalysisChapters: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTERS.records,
  wkbRiskAnalysisChapterStatuses: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTER_STATUSES.records,
  wkbRiskAnalysisChapterSubstantiations: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATIONS.records,
  wkbRiskAnalysisChapterSubstantiationDocuments: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS.records,
  wkbRiskAnalysisChapterSubstantiationDocumentsTableId: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS.id,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
