import {
  createRecord,
  deleteRecord,
  getRecords,
  importRecords,
  updateRecord,
} from '@/services/api/record.api';
import { bookOfObjects } from '@/services/bookOf';
import { queryTasksV2 } from '@/services/api/v2/tasks.v2.api';
import {
  deleteRecordV2,
  updateRecordV2,
} from '@/services/api/v2/records.v2.api';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';

const state = {
  moduleStatus: '',

  bomTasks: [],
  assemblyPhase: null,

  resourceGroups: [],
  resourceGroupsTable: null,

  modules: [],
  modulesTable: null,

  nonModules: [],
  nonModulesTable: null,

  elements: [],
  elementsTable: null,

  focusedGroup: null,
  groupElements: [],
  groupElementsTable: null,
  groupObjects: [],
  groupObjectsTable: null,
};
const mutations = {
  module_bill_of_material_request(state) {
    state.moduleStatus = 'loading';
  },
  module_bill_of_material_success(state) {
    state.moduleStatus = 'success';
  },
  module_bill_of_material_focus_on_group(state, group) {
    state.focusedGroup = group;
  },
  module_bill_of_material_group_elements_and_objects_success(
    state,
    { elements, objects }
  ) {
    state.groupElements = elements.records;
    state.groupElementsTable = elements.id;
    state.groupObjects = objects.records;
    state.groupObjectsTable = objects.id;
  },
  module_bill_of_material_resource_groups_success(state, resourceGroups) {
    state.resourceGroups = resourceGroups.records;
    state.resourceGroupsTable = resourceGroups.id;
  },
  module_bill_of_material_modules_success(state, modules) {
    state.modules = modules.records;
    state.modulesTable = modules.id;
  },
  module_bill_of_material_non_modules_success(state, nonModules) {
    state.nonModules = nonModules.records;
    state.nonModulesTable = nonModules.id;
  },
  module_bill_of_material_elements_success(state, elements) {
    state.elements = elements.records;
    state.elementsTable = elements.id;
  },
  module_bill_of_material_group_create_success(state, group) {
    state.resourceGroups.push(group);
  },
  module_bill_of_material_group_update_success(state, group) {
    Object.assign(
      state.resourceGroups.find((x) => x.id === group.id),
      group
    );
  },
  module_bill_of_material_group_delete_success(state, deletedGroupId) {
    state.resourceGroups = state.resourceGroups.filter(
      (group) => group.id !== deletedGroupId
    );
    state.focusedGroup = null;
    state.groupElements = [];
    state.groupObjects = [];
  },
  module_bill_of_material_group_object_create_success(
    state,
    groupObjectRelation
  ) {
    state.groupObjects.push(groupObjectRelation);
  },
  module_bill_of_material_group_element_create_success(
    state,
    groupElementRelation
  ) {
    state.groupElements.push(groupElementRelation);
  },
  module_bill_of_material_group_object_update_success(
    state,
    updatedGroupGroupObjectRelation
  ) {
    Object.assign(
      state.groupObjects.find(
        (relation) => relation.id === updatedGroupGroupObjectRelation.id
      ),
      updatedGroupGroupObjectRelation
    );
  },
  module_bill_of_material_group_element_update_success(
    state,
    updatedGroupGroupElementRelation
  ) {
    Object.assign(
      state.groupElements.find(
        (relation) => relation.id === updatedGroupGroupElementRelation.id
      ),
      updatedGroupGroupElementRelation
    );
  },
  module_bill_of_material_group_object_delete_success(
    state,
    deletedGroupGroupObjectRelationId
  ) {
    state.groupObjects = state.groupObjects.filter(
      (relation) => relation.id !== deletedGroupGroupObjectRelationId
    );
  },
  module_bill_of_material_group_element_delete_success(
    state,
    deletedGroupGroupElementRelationId
  ) {
    state.groupElements = state.groupElements.filter(
      (relation) => relation.id !== deletedGroupGroupElementRelationId
    );
  },
  module_bill_of_material_group_object_import_success(state, records) {
    state.groupObjects = records;
  },
  module_bill_of_material_group_element_import_success(state, records) {
    state.groupElements = records;
  },
  module_bill_of_material_tasks_request(state) {
    state.moduleStatus = 'loading';
  },
  module_bill_of_material_work_package_request_success(state, tasks) {
    state.bomTasks = state.bomTasks.concat(tasks);
    state.moduleStatus = 'success';
  },
  module_bill_of_material_tasks_success(state, tasks) {
    state.bomTasks = tasks;
    state.moduleStatus = 'success';
  },
};
const actions = {
  async fetchBillOfMaterialTasks({ commit, getters }) {
    commit('module_bill_of_material_tasks_request');
    const response = await queryTasksV2(getters.selectedLicense.id, [
      {
        column: 'project',
        operator: '=',
        values: [getters.project.id],
      },
      {
        column: 'type',
        operator: '=',
        values: [
          'dhme-module-assembly',
          'dhme-production-phase',
          'dhme-placement-phase',
          'dhme-work-package',
          'dhme-assembly-phase',
        ],
      },
    ]);

    commit('module_bill_of_material_tasks_success', response.tasks);
  },
  async fetchBillOfMaterialData({ commit, rootGetters }) {
    commit('module_bill_of_material_request');
    let { groups, modules, nonModules, elements } = await queryTablesV2({
      tables: [
        {
          name: 'CFFA_DHME_RESOURCEGROUPS',
          project: rootGetters.project.id,
          as: 'groups',
          columns: [
            {
              name: 'name',
            },
            {
              name: 'group_code',
            },
          ],
        },
        {
          name: 'CFFA_DHME_MODULES',
          project: rootGetters.project.id,
          as: 'modules',
          sortBy: 'assembly_sequence',
          columns: [
            {
              name: 'module_id',
            },
            {
              name: 'module_type',
            },
            {
              name: 'build_nr',
            },
            {
              name: 'house_nr',
            },
            {
              name: 'assembly_sequence',
            },
            {
              name: 'placement_sequence',
            },
            {
              name: 'assembly_workload',
            },
            {
              name: '2d_drawing',
            },
          ],
        },
        {
          name: 'CFFA_DHME_ELEMENTS',
          project: rootGetters.project.id,
          as: 'elements',
          columns: [
            {
              name: 'module_id',
            },
            {
              name: 'module_type',
            },
            {
              name: 'element_id',
            },
            {
              name: 'element_type',
            },
          ],
        },
        {
          name: 'CFFA_DHME_NON_MODULES',
          project: rootGetters.project.id,
          as: 'nonModules',
          columns: [
            {
              name: 'non_module_id',
            },
            {
              name: 'phase',
            },
          ],
        },
      ],
    });
    commit('module_bill_of_material_resource_groups_success', groups);
    commit('module_bill_of_material_modules_success', modules);
    commit('module_bill_of_material_non_modules_success', nonModules);
    commit('module_bill_of_material_elements_success', elements);

    commit('module_bill_of_material_success');
  },
  async focusOnGroup({ commit, rootGetters }, group) {
    commit('module_bill_of_material_focus_on_group', group);
    let { elements, objects } = await queryTablesV2({
      tables: [
        {
          name: 'CFFA_DHME_RESOURCEGROUP_ELEMENTS',
          project: rootGetters.project.id,
          as: 'elements',
          columns: [
            {
              name: 'group_code',
              conditions: [
                {
                  operator: '=',
                  value: group.group_code,
                },
              ],
            },
            {
              name: 'name',
            },
            {
              name: 'description',
            },
            {
              name: 'element_type',
            },
          ],
        },
        {
          name: 'CFFA_DHME_RESOURCEGROUP_OBJECTS',
          project: rootGetters.project.id,
          as: 'objects',
          columns: [
            {
              name: 'group_code',
              conditions: [
                {
                  operator: '=',
                  value: group.group_code,
                },
              ],
            },
            {
              name: 'name',
            },
            {
              name: 'description',
            },
            {
              name: 'object_type',
            },
          ],
        },
      ],
    });
    commit('module_bill_of_material_group_elements_and_objects_success', {
      elements,
      objects,
    });
  },
  createResourceGroup({ commit, state, getters }, { record }) {
    return createRecord({
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.resourceGroupsTable,
      },
      record: record,
    })
      .then((response) => {
        commit('module_bill_of_material_group_create_success', response);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  async updateResourceGroupName({ commit, state, getters }, { name }) {
    try {
      const group = await updateRecordV2(
        state.resourceGroupsTable,
        state.focusedGroup.id,
        {
          name,
        }
      );
      commit('module_bill_of_material_group_update_success', group);
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },
  async removeResourceGroup({ commit, state, getters, rootGetters }, group) {
    try {
      await deleteRecordV2(
        state.groupElementsTable,
        ...state.groupElements.map((x) => x.id)
      );
      await deleteRecordV2(
        state.groupObjectsTable,
        ...state.groupObjects.map((x) => x.id)
      );
      await deleteRecordV2(state.resourceGroupsTable, group.id);

      commit('module_bill_of_material_group_delete_success', group.id);
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
    }
  },
  createResourceGroupObjectRelation(
    { commit, state, getters },
    { record, tableState }
  ) {
    const tableId =
      tableState === 'Elements'
        ? state.groupObjectsTable
        : state.groupObjectsTable;

    return createRecord({
      project: {
        id: getters.project.id,
      },
      table: {
        id: tableId,
      },
      record: record,
    })
      .then((response) => {
        switch (tableState) {
          case 'Elements':
            commit(
              'module_bill_of_material_group_element_create_success',
              response
            );
            break;
          case 'Objects':
            commit(
              'module_bill_of_material_group_object_create_success',
              response
            );
            break;
        }
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  updateResourceGroupObjectRelation(
    { commit, state, getters },
    { recordId, record, tableState }
  ) {
    const tableId =
      tableState === 'Elements'
        ? state.groupObjectsTable
        : state.groupObjectsTable;

    return updateRecord(recordId, {
      project: {
        id: getters.project.id,
      },
      table: {
        id: tableId,
      },
      record: record,
    })
      .then((response) => {
        switch (tableState) {
          case 'Elements':
            commit(
              'module_bill_of_material_group_element_update_success',
              response
            );
            break;
          case 'Objects':
            commit(
              'module_bill_of_material_group_object_update_success',
              response
            );
            break;
        }
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  removeResourceGroupObjectRelation(
    { commit, state, getters },
    { recordId, tableState }
  ) {
    const tableId =
      tableState === 'Elements'
        ? state.groupObjectsTable
        : state.groupObjectsTable;

    return deleteRecord(recordId, {
      project: {
        id: getters.project.id,
      },
      table: {
        id: tableId,
      },
    })
      .then((response) => {
        switch (tableState) {
          case 'Elements':
            commit(
              'module_bill_of_material_group_element_delete_success',
              recordId
            );
            break;
          case 'Objects':
            commit(
              'module_bill_of_material_group_object_delete_success',
              recordId
            );
            break;
        }
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  importMultipleGroupObjectRelations(
    { commit, getters, state },
    { articles, tableState }
  ) {
    let book = bookOfObjects('articles', articles);
    let csv = book.convert('csv', 'string');
    let parsedCsv = btoa(csv);
    const tableId =
      tableState === 'Elements'
        ? state.groupElementsTable
        : state.groupObjectsTable;
    return importRecords({
      project: {
        id: getters.project.id,
      },
      table: {
        id: tableId,
      },
      records: parsedCsv,
    })
      .then((response) => {
        getRecords(getters.project.id, tableId).then((records) => {
          switch (tableState) {
            case 'Elements':
              commit(
                'module_bill_of_material_group_element_import_success',
                records
              );
              break;
            case 'Objects':
              commit(
                'module_bill_of_material_group_object_import_success',
                records
              );
              break;
          }
        });
        this.commit('showNotification', {
          content: `successfully imported ${articles.length} articles`,
          color: 'success',
        });
        return response;
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};
const getters = {
  dhmeBillOfMaterialStatus: (state) => state.moduleStatus,
  dhmeBillOfMaterialFocusedGroup: (state) => state.focusedGroup,
  dhmeBillOfMaterialResourceGroups: (state) => state.resourceGroups,
  dhmeBillOfMaterialResourceGroupElements: (state) => state.groupElements,
  dhmeBillOfMaterialResourceGroupObjects: (state) => state.groupObjects,
  dhmeBillOfMaterialModules: (state) => state.modules,
  dhmeBillOfMaterialNonModules: (state) => state.nonModules,
  dhmeBillOfMaterialElements: (state) => state.elements,
  dhmeBillOfMaterialPlacementTasks: (state) =>
    state.bomTasks.filter(
      (task) => task?.task_type?.type === 'dhme-placement-phase'
    ),
  dhmeBillOfMaterialAssemblyTasks: (state) =>
    state.bomTasks.filter(
      (task) => task?.task_type?.type === 'dhme-module-assembly'
    ),
  dhmeBillOfMaterialProductionTask: (state) =>
    state.bomTasks.find(
      (task) => task?.task_type?.type === 'dhme-production-phase'
    ),
  dhmeBillOfMaterialWorkPackagesTasks: (state) =>
    state.bomTasks.filter(
      (task) => task?.task_type?.type === 'dhme-work-package'
    ),
  dhmeBillOfMaterialAssemblyPhase: (state) =>
    state.bomTasks.filter(
      (task) => task?.task_type?.type === 'dhme-assembly-phase'
    ),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
