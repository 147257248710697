import { httpClientV2 } from '@/services/httpClient';

export const importTasksV2 = (data) => {
  return new Promise((resolve, reject) => {
    httpClientV2
      .post(`/tasks`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export const queryTasksV2 = (license, filters, depth = undefined) => {
  return new Promise((resolve, reject) => {
    httpClientV2
      .post(`licenses/${license}/tasks/query`, {
        filters: filters,
        depth: depth,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export const queryTasksAdvanced = (licenseId, query) => {
  return new Promise((resolve, reject) => {
    httpClientV2
      .get(`/licenses/${licenseId}/tasks?${query}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export const getTaskV2 = (taskId) => {
  return new Promise((resolve, reject) => {
    httpClientV2
      .get(`tasks/${taskId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export const uploadAppendixV2 = (taskId, data) => {
  return new Promise((resolve, reject) => {
    httpClientV2
      .post(`tasks/${taskId}/appendixes`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export const downloadAppendixV2 = (taskId, appendixId) => {
  return new Promise((resolve, reject) => {
    httpClientV2
      .get(`tasks/${taskId}/appendixes/${appendixId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export const removeAppendixV2 = (taskId, appendixId) => {
  return new Promise((resolve, reject) => {
    httpClientV2
      .delete(`tasks/${taskId}/appendixes/${appendixId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export const createMessageV2 = (taskId, data) => {
  return new Promise((resolve, reject) => {
    httpClientV2
      .post(`tasks/${taskId}/messages`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};
